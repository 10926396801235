import React, { useState, useEffect, useRef } from "react";
import { TweenLite, Power4 } from 'gsap';
import "./cursor.css"
import {graphql, useStaticQuery} from "gatsby";

const Cursor = (props) => {
  const [timer, setTimer] = useState(null);
  const cursor = useRef(null);
  const cursor2 = useRef(null);

  const data = useStaticQuery(graphql`
    query {
      tomImage: file(relativePath: { eq: "tom.webp" }) {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  `);

  useEffect(() => {

    const mouseClickHandler = event => {
      cursor.current.classList.add('is-moving');
      cursor2.current.classList.add('is-moving');
      document.body.style.backgroundImage = "repeating-linear-gradient(180deg, #1126F8, #1126F8 5px, #F1A896 5px, #F1A896 10px, #44D3F2 10px, #44D3F2 15px)";

      if (timer !== null ) {
        clearTimeout(timer);
      }
      const newTimer = setTimeout( () => {
        if (cursor.current && cursor2.current ) {
          cursor.current.classList.remove('is-moving');
          cursor2.current.classList.remove('is-moving');
          document.body.style.backgroundImage = `url(${props.poster})`;
        }
      }, 300);
      setTimer(newTimer)
    };

    const mouseMoveHandler = event => {

      cursor.current.classList.add('is-moving');
      cursor2.current.classList.add('is-moving');

      TweenLite.to(cursor.current, 0.23, {
        left: event.pageX,
        top: event.pageY,
        ease: Power4.easOut
      });

      TweenLite.to(cursor2.current, 0.75, {
        left: event.pageX,
        top: event.pageY,
        ease: Power4.easOut
      });


      if (timer !== null ) {
        clearTimeout(timer);
      }
      const newTimer = setTimeout( () => {
        cursor.current.classList.remove('is-moving');
        cursor2.current.classList.remove('is-moving');
      }, 300);

      setTimer(newTimer)
    };

    document.addEventListener("mousemove", mouseMoveHandler);
    document.addEventListener("mousedown", mouseClickHandler);
    return () => {
      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mousedown", mouseClickHandler);
    };
  }, []); // empty-array means don't watch for any updates

  return (
    <>
      <div ref={cursor} className={ props.focused ? 'cursor hide' : 'cursor' }/>
      <div ref={cursor2} className={ props.focused ? 'cursor2 hide' : 'cursor2' }/>
    </>
  )
}

export default Cursor
