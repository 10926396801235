/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import Cursor from "./cursor"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"


import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  // @font-face {
  //   font-family: 'Apple Chancery';
  //   src: local('Apple Chancery'),
  //        url('/AppleChancery.woff2') format('woff2'),
  //        url('/AppleChancery.woff') format('woff');
  //
  //   font-weight: normal;
  //   font-style: italic;
  // }

  body {
    font-family: 'AppleChancery', sans-serif;
    background-image: url(${props => props.poster});
    @media (max-width: 800px) {
      //background-size: cover;
    }
  }
`;

// import figlet from 'figlet';

console.log(
  " ____    _    _     _  __    _    _   _ ____  \n" +
  " | __ )  / \\  | |   | |/ /   / \\  | \\ | / ___| \n" +
  " |  _ \\ / _ \\ | |   | ' /   / _ \\ |  \\| \\___ \\ \n" +
  " | |_) / ___ \\| |___| . \\  / ___ \\| |\\  |___) |\n" +
  " |____/_/   \\_\\_____|_|\\_\\/_/   \\_\\_| \\_|____/ "
);

console.log = console.warn = console.error = () => {};


const Layout = ({ children, poster }) => {
  const data = useStaticQuery(graphql`
    query {
      siteTitleQuery: site {
        siteMetadata {
          title
        }
      }
      troubledSong: file(relativePath: { eq: "troubled.mp3" }) {
        publicURL
      }
    }
  `);

  let audio = null;
  if (typeof window !== 'undefined' && window) {
    audio = new Audio(data.troubledSong.publicURL);
    audio.volume= 0.1;
  }

  React.useEffect(() => {

    const mouseClickHandler = () => {
      if (typeof window !== 'undefined' && window) {
        if (audio.currentTime === 0 || audio.ended) {
          audio.play()
        } else if (audio.paused && audio.currentTime > 0 && !audio.ended) {
          audio.play();
        } else {
          audio.pause();
        }
      }
    };

    document.addEventListener("mousedown", mouseClickHandler);

    return () => {
      document.removeEventListener("mousedown", mouseClickHandler);
    };
  },[]);

  return (
    <>
      <GlobalStyles
        poster={poster}
      />
      <Header siteTitle={data.siteTitleQuery.siteMetadata.title}/>
      {/*<Cursor />*/}
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
        id={'#app'}
      >
        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()}, Built with love by <a href={'mailto:balkansbalkans@gmail.com'}>balkansbalkans@gmail.com</a>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
