import { SplitColorChannelText } from 'react-text-fun';
import PropTypes from "prop-types"
import {
  useWindowSize,
} from '@react-hook/window-size'
import React, {useEffect} from "react"
import { Link } from 'gatsby'

const Header = (props) => {

  const [width, height] = useWindowSize(
    360 /* initialWidth when there is no window */,
    720 /* initialHeight when there is no window */,
    {wait: 100}
  );

  const [rotation, setRotation] = React.useState(0);
  const [rgbOffset, setRgbOffset] = React.useState(0);

  const mouseMoveHandler = event => {
    setRotation((event.pageX / width)/20)
    setRgbOffset((event.pageY / height)/20)
  };

  useEffect(() => {
    document.addEventListener("mousemove", mouseMoveHandler);
    return () => {
      document.removeEventListener("mousemove", mouseMoveHandler);
    };
  }, []); // empty-array means don't watch for any updates

  return (
    <header
      style={{
        marginBottom: `1.45rem`,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `1.45rem 1.0875rem`,
          textAlign: 'center'
        }}
      >
        <Link to={'/'}>
          <SplitColorChannelText
            text="Balkans"
            fontSize={width < 1000 ? 75 : 200}
            rotation={rotation}
            rgbOffset={rgbOffset}
            addBlur={true}
            addNoise={true}
            fontFamily={'AppleChancery'}
          />
        </Link>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
